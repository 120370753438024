import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Container } from "react-bootstrap";

export const Banner = ({ id, link, image }) => {
	return (
		<Container id={id} className="element element__banner">
			{link ? (
				<Link to={link.url}>
					<GatsbyImage image={image.localFile.childImageSharp?.gatsbyImageData} alt={image.altText} />
				</Link>
			) : (
				<GatsbyImage image={image.localFile.childImageSharp?.gatsbyImageData} alt={image.altText} />
			)}
		</Container>
	);
};

Banner.propTypes = {
	id: PropTypes.any.isRequired,
	link: PropTypes.object,
	image: PropTypes.object.isRequired,
};
