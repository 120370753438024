import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Container, Row, Col } from "react-bootstrap";
import capitalize from "@utils/capitalize";
import * as styles from "./LogoSlider.module.scss";
import "swiper/css";
import "swiper/css/pagination";
import "@assets/css/swiper.scss";

export const LogoSlider = ({ id, title, text, logos, type }) => {
	return (
		<Container id={id} className={`element logo-slider`}>
			<Row className={styles.logoSlider__content}>
				<Col>
					{title && <h2>{title}</h2>}
					{text && <p>{text}</p>}
				</Col>
			</Row>

			{type === "carousel" && logos ? (
				<Swiper
					modules={[Pagination]}
					spaceBetween={5}
					pagination={{ clickable: true }}
					breakpoints={{
						0: {
							slidesPerView: 1,
						},
						576: {
							slidesPerView: 1,
						},
						768: {
							slidesPerView: 2,
						},
						1024: {
							slidesPerView: 3,
						},
						1200: {
							slidesPerView: 4,
						},
						1440: {
							slidesPerView: 5,
						},
					}}>
					{logos &&
						logos.map((logo, i) => (
							<SwiperSlide key={i}>
								<GatsbyImage
									image={logo.logo.localFile.childImageSharp?.gatsbyImageData}
									alt={`${logo.logo.altText ? logo.logo.altText : ""}`}
								/>
							</SwiperSlide>
						))}
				</Swiper>
			) : (
				logos && (
					<Row>
						<Col className={styles[`logoSlider${capitalize(type)}__wrapper`]}>
							{logos.map((logo, i) => (
								<GatsbyImage
									key={i}
									image={logo.logo.localFile.childImageSharp?.gatsbyImageData}
									alt={`${logo.logo.altText ? logo.logo.altText : ""}`}
								/>
							))}
						</Col>
					</Row>
				)
			)}
		</Container>
	);
};

LogoSlider.propTypes = {
	id: PropTypes.any.isRequired,
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	logos: PropTypes.array,
	type: PropTypes.oneOf(["default", "list", "carousel"]).isRequired,
};

LogoSlider.defaultProps = {
	type: "default",
};
